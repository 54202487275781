// ProjectCharacteristicsList.tsx
import { List } from 'antd';
import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  rightColumnElement: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    background: 'white',
    borderRadius: '6px',
    '& .ant-list-grid .ant-col > .ant-list-item': {
      marginBottom: '0px',
    },
    '& .ant-row': {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
  },
  tag: {
    '& .ant-tag': {
      margin: '0px 6px 0px 0px',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      padding: 0,
      wordBreak: 'break-word',
      whiteSpace: 'unset',
      fontSize: '16px',
      lineHeight: '16.8px',
      fontWeight: '400',
      fontFamily: 'PP Mori',
    },
  },
});

interface ProjectCharacteristic {
  title: React.ReactNode;
  description: React.ReactNode;
  valid: string[] | string | boolean | Date | undefined;
}

interface Props {
  characteristics: ProjectCharacteristic[];
}

export const ProjectCharacteristicsList: React.FC<Props> = ({
  characteristics,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.rightColumnElement}>
      <List
        grid={{ gutter: 0, column: 1 }}
        dataSource={characteristics}
        className={classes.tag}
        split={false}
        renderItem={(item) =>
          !!item.valid && (
            <List.Item>
              <List.Item.Meta
                title={item.title}
                description={item.description}
              />
            </List.Item>
          )
        }
      />
    </div>
  );
};
