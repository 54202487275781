import { Button, Input, Modal } from 'antd';
import Tag from 'antd/es/tag';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { FullscreenSpin } from '../../components/FullscreenSpin';
import { CallForTender, reportCFTProblem } from 'src/slices/callForTender';
import Text from 'antd/lib/typography/Text';
import { ProjectCharacteristicsList } from '../../components/lists/ProjectCharacteristicsList';
import { OddImages } from '../../components/OddImages';
import { PlacesModal } from '../../components/PlacesModal';
import { TagListForDonorAndNgoPages } from '../../components/TagListForNgoAndDonorPages';
import { theme } from '../../constants/theme';
import { Classification, Place } from '../../slices/classification';
import { useAppDispatch } from 'src/hooks/store';

const { TextArea } = Input;

const useStyles = createUseStyles({
  subtitle: {
    color: theme.palette.primary,
    fontFamily: 'PP Mori',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '12.6px',
  },
  rightColumn: {
    flexGrow: 1,
    maxWidth: '230px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginLeft: '20px',
    gap: '20px',
  },
  problemButton: {
    fontFamily: 'PP Mori',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '12.6px',
    textDecoration: 'underline solid',
    color: '#797676 !important',
    '&:hover': {
      cursor: 'pointer',
      color: '#000 !important',
    },
  },
  tag: {
    '& .ant-tag': {
      margin: '0px 6px 0px 0px',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      padding: 0,
      wordBreak: 'break-word',
      whiteSpace: 'unset',
      fontSize: '16px',
      lineHeight: '16.8px',
      fontWeight: '400',
      fontFamily: 'PP Mori',
    },
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  dateElement: {
    marginRight: '6px',
    fontSize: '0.75vw',
    color: 'black',
    lineHeight: 1,
  },
  rightColumnElement: {
    display: 'flex',
    flexDirection: 'column',
    padding: '9.71% 6.59% 10.68% 6.8%',
    marginBottom: '3.3vh',
    background: 'white',
    borderRadius: '0.3125vw',
  },
  rightColumnLastElement: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10.58% 7.28% 6.16% 7.758%',
    background: 'white',
    borderRadius: '0.3125vw',
    position: 'relative',
    '& .ant-list-item-meta-content': {
      marginTop: 'auto',
      marginBottom: 'auto',
      '& .ant-list-item-meta-description': {
        color: 'black',
        fontSize: '0.75vw',
      },
    },
    '& .ant-list-item-meta-avatar': {
      marginRight: '2.87%',
    },
    '& .ant-avatar': {
      height: 'auto',
      width: 'auto',
      lineHeight: 1,
    },
  },
  status: {
    marginTop: '4px',
    fontSize: '0.75vw',
  },
});

interface Props {
  callForTender: CallForTender;
  classification: Classification;
}

const statusTranslation = [
  'Programme de soutien en cours',
  'Programme de soutien clos',
  'Programme de soutien permanent',
  'Programme de soutien à venir',
];
const status = ['open', 'closed', 'permanent', 'coming'];

const CallForTenderRightColumn: React.VFC<Props> = ({
  callForTender,
  classification,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [visible, setVisible] = React.useState(false);
  const [editedProblem, setEditedProblem] = React.useState<string>('');

  const [showPlacesModal, setShowPlacesModal] = useState(false);
  const {
    donationTypes,
    activityDomains,
    targetPopulations,
    places,
    sustainableDevelopmentGoals,
  } = classification;

  if (!callForTender) return <FullscreenSpin />;

  const onEditProblem = (value: string) => {
    setEditedProblem(value);
  };

  const onSubmitProblem = () => {
    if (editedProblem) {
      dispatch(
        reportCFTProblem({
          _id: callForTender._id,
          name: callForTender.name,
          problemDescription: editedProblem,
        }),
      );
      setVisible(false);
    }
    setEditedProblem('');
  };

  const renderDate = (
    openingDate: Date | undefined,
    closingDate: Date | undefined,
  ) => {
    const newOpeningDate = openingDate ? new Date(openingDate) : undefined;
    const newClosingDate = closingDate ? new Date(closingDate) : undefined;
    return (
      <div className={classes.dateContainer}>
        {newOpeningDate && (
          <Text className={classes.tag}>
            {(newClosingDate && 'Du ') || 'À partir du '}
            {new Intl.DateTimeFormat('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            }).format(newOpeningDate)}{' '}
          </Text>
        )}
        {newClosingDate && (
          <Text className={classes.tag}>
            {(newOpeningDate && 'au ') || "Jusqu'au "}
            {new Intl.DateTimeFormat('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
            }).format(newClosingDate)}
          </Text>
        )}
      </div>
    );
  };

  const renderStatus = () => {
    const statusToPrint = status.findIndex(
      (status) => status === callForTender?.status,
    );
    return (
      <Text className={classes.tag}>{statusTranslation[statusToPrint]}</Text>
    );
  };

  const [countries, setCountries] = useState<Place[]>([]);
  const [france, setFrance] = useState<Place[]>([]);

  useEffect(() => {
    if (places) {
      setCountries(
        places
          .filter((place) => place.countries && place.countries.length)
          .sort((a, b) => a.name.localeCompare(b.name)) as Place[],
      );
      setFrance(
        places
          .filter((place) => place.departments && place.departments.length)
          .sort((a, b) =>
            a.name.includes('DROM') ||
            a.name.includes("Collectivités d'Outre-Mer")
              ? 1
              : a.name.localeCompare(b.name),
          ) as Place[],
      );
    }
  }, [places]);

  function renderFrance(depIds: string[]): string | undefined {
    if (!depIds || depIds.length === 0) return undefined;
    let isFranceComplete = true;
    france.forEach((place) => {
      if (place.departments) {
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isFranceComplete = false;
          }
        });
      }
    });
    if (isFranceComplete) {
      return 'France';
    }
    return undefined;
  }

  function renderDepartments(depIds: string[]): string[] {
    if (!depIds || depIds.length === 0) return [];
    const results: string[] = [];
    france.forEach((place) => {
      let isRegionComplete = true;
      if (place.departments) {
        const departments = [] as string[];
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isRegionComplete = false;
          } else {
            departments.push(department.name);
          }
        });
        if (!isRegionComplete) {
          departments.forEach((department) => {
            results.push(department);
          });
        }
      }
      if (isRegionComplete) {
        results.push(place.name);
      }
    });
    return results.length > 0 ? results : [];
  }

  function renderCountries(placesId: string[]) {
    if (!placesId || placesId.length === 0) return [];
    const results: string[] = [];
    countries.forEach((place) => {
      let isContinentComplete = true;
      if (place.countries) {
        const countries = [] as string[];
        place.countries.forEach((currCountry) => {
          if (!placesId.includes(currCountry._id)) {
            isContinentComplete = false;
          } else {
            countries.push(currCountry.name);
          }
        });
        if (!isContinentComplete) {
          countries.forEach((currentCountry) => {
            results.push(currentCountry);
          });
        }
      }
      if (isContinentComplete) {
        if (place.name === 'Europe') {
          results.push('Europe');
        } else {
          results.push(place.name);
        }
      }
    });
    return results.length > 0 ? results : [];
  }

  function renderPlaces(placeIds: string[]): JSX.Element[] {
    if (!placeIds || placeIds.length === 0) return [];
    let results = [] as string[];
    if (renderFrance(placeIds)) {
      const isFrance = renderFrance(placeIds);
      if (isFrance) {
        results = results.concat([isFrance]);
      }
    } else {
      if (renderDepartments(placeIds)) {
        results = results.concat(renderDepartments(placeIds));
      }
    }
    if (renderCountries(placeIds)) {
      results = results.concat(renderCountries(placeIds));
    }
    return results.length > 0
      ? results.map((department, index) =>
          index !== results.length - 1 ? (
            <Tag key={index} className={classes.tag}>
              {department}
              {', '}
            </Tag>
          ) : (
            <Tag key={index} className={classes.tag}>
              {department}
            </Tag>
          ),
        )
      : [];
  }

  const callForTenderCharacteristic = [
    {
      title: <Text className={classes.subtitle}>Statut</Text>,
      description: renderStatus(),
      valid: callForTender.status,
    },
    {
      title: (
        <Text className={classes.subtitle}>
          Modalités du programme de soutien
        </Text>
      ),
      description:
        callForTender.modusOperandi &&
        callForTender.modusOperandi.map((modus) => (
          <div className={classes.tag} key={modus}>
            <Tag>{modus}</Tag>
          </div>
        )),
      valid:
        callForTender.modusOperandi && callForTender.modusOperandi.length > 0,
    },
    callForTender.openingDate || callForTender.closingDate
      ? {
          title: <Text className={classes.subtitle}>Date</Text>,
          description: renderDate(
            callForTender?.openingDate,
            callForTender?.closingDate,
          ),
          valid: true,
        }
      : null,
    callForTender.donationTypes && callForTender.donationTypes.length > 0
      ? {
          title: <Text className={classes.subtitle}>Type de mécénat</Text>,
          description: (
            <TagListForDonorAndNgoPages
              elementIds={callForTender.donationTypes}
              collection={donationTypes}
              color="default"
              collectionName="donationTypes"
            />
          ),
          valid: true,
        }
      : null,
    callForTender.activityDomains && callForTender.activityDomains.length > 0
      ? {
          title: (
            <Text className={classes.subtitle}>
              Domaines d&apos;intervention
            </Text>
          ),
          description: (
            <TagListForDonorAndNgoPages
              elementIds={callForTender.activityDomains}
              collection={activityDomains}
              color="default"
              collectionName="activityDomains"
            />
          ),
          valid: true,
        }
      : null,
    callForTender.targetPopulations &&
    callForTender.targetPopulations.length > 0
      ? {
          title: <Text className={classes.subtitle}>Bénéficiaires cibles</Text>,
          description: (
            <TagListForDonorAndNgoPages
              elementIds={callForTender.targetPopulations}
              collection={targetPopulations}
              color="default"
              collectionName="targetPopulations"
            />
          ),
          valid: true,
        }
      : null,
    callForTender.places && callForTender.places.length > 0
      ? {
          title: (
            <Text className={classes.subtitle}>{"Lieu(x) d'intervention"}</Text>
          ),
          description: (
            <>
              {renderPlaces(callForTender.places).slice(0, 10)}
              {renderPlaces(callForTender.places).length > 10 && (
                <Button
                  size={'small'}
                  type="link"
                  onClick={() => setShowPlacesModal(true)}
                >
                  Voir Tout
                </Button>
              )}
            </>
          ),
          valid: true,
        }
      : null,
    callForTender.roadAndNumber && callForTender.city && callForTender.zipcode
      ? {
          title: (
            <Text className={classes.subtitle}>{"Lieu(x) d'intervention"}</Text>
          ),
          description: (
            <TagListForDonorAndNgoPages
              elementIds={[callForTender.roadAndNumber]}
              collection={[
                {
                  _id: callForTender.roadAndNumber,
                  name: `${callForTender.roadAndNumber}, ${callForTender.city}, ${callForTender.zipcode}`,
                },
              ]}
              color="default"
              collectionName="uniqueCustomAddress"
            />
          ),
          valid: true,
        }
      : null,
    callForTender.uniqueZipcode
      ? {
          title: (
            <Text className={classes.subtitle}>{"Lieu(x) d'intervention"}</Text>
          ),
          description: (
            <TagListForDonorAndNgoPages
              elementIds={[callForTender.uniqueZipcode]}
              collection={[
                {
                  _id: callForTender.uniqueZipcode,
                  name: `${callForTender.uniqueZipcode}, ${callForTender.city}`,
                },
              ]}
              color="default"
              collectionName="uniqueZipcode"
            />
          ),
          valid: true,
        }
      : null,
  ].filter((item): item is NonNullable<typeof item> => item !== null);

  useEffect(() => {
    console.log(callForTender.fundedNgos);
  }, [callForTender.fundedNgos]);

  return (
    <div className={classes.rightColumn}>
      <Text className={classes.problemButton} onClick={() => setVisible(true)}>
        Signaler une anomalie avec cette fiche
      </Text>
      {/*TODO: add logoUrl to cft*/}
      {/*{callForTender.logoUrl && (*/}
      {/*  <img*/}
      {/*    src={callForTender.logoUrl}*/}
      {/*    style={{*/}
      {/*      height: '106px',*/}
      {/*      objectFit: 'contain',*/}
      {/*      alignSelf: 'self-start',*/}
      {/*    }}*/}
      {/*    alt={callForTender.name}*/}
      {/*  />*/}
      {/*)}*/}
      <ProjectCharacteristicsList
        characteristics={callForTenderCharacteristic}
      />
      <OddImages
        sustainableDevelopmentGoals={sustainableDevelopmentGoals}
        oddIds={callForTender.sustainableDevelopmentGoals || []}
      />
      <PlacesModal
        ngoPlaces={
          callForTender.places && callForTender.places.length > 0
            ? callForTender.places
            : []
        }
        places={places}
        isVisible={showPlacesModal}
        requestClose={() => setShowPlacesModal(false)}
      />
      <Modal
        open={visible}
        title="Signalement d'une fiche programme de soutien"
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setVisible(false);
              setEditedProblem('');
            }}
          >
            Annuler
          </Button>,
          <Button key="submit" type="primary" onClick={() => onSubmitProblem()}>
            Valider
          </Button>,
        ]}
      >
        <TextArea
          placeholder="Merci de préciser le sujet avec la fiche de ce programme de soutien"
          rows={4}
          onChange={(e) => onEditProblem(e.target.value)}
          value={editedProblem}
        />
      </Modal>
    </div>
  );
};

export default CallForTenderRightColumn;
